var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "调货出库",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "出库记录"
    },
    on: {
      "click-right": _vm.toRecordView
    }
  }), _c('div', {
    staticClass: "app-main-container list"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (transOrder, index) {
    return _c('div', {
      key: index,
      staticClass: "accept-item",
      on: {
        "click": function click($event) {
          return _vm.toDetailView(transOrder.id);
        }
      }
    }, [_c('van-cell', {
      attrs: {
        "title": "".concat(transOrder.from_store.name, "\u2192").concat(transOrder.to_store.name),
        "value": transOrder.created_at
      }
    }), _c('van-cell', {
      attrs: {
        "title": "".concat(transOrder.kind, "\u79CD\u5546\u54C1"),
        "value": "\u5171\uFFE5".concat(transOrder.cost_amount)
      }
    })], 1);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }