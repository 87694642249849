<template>
  <div>
    <my-nav-bar
      title="调货出库"
      left-text="返回"
      left-arrow
      right-text="出库记录"
      @click-right="toRecordView"
    />
    <div class="app-main-container list">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div v-for="(transOrder, index) in list" :key="index" class="accept-item" @click="toDetailView(transOrder.id)">
        <van-cell :title="`${transOrder.from_store.name}→${transOrder.to_store.name}`" :value="transOrder.created_at" />
        <van-cell :title="`${transOrder.kind}种商品`" :value="`共￥${transOrder.cost_amount}`" />
      </div>
      <!-- <div v-if="hasBusinessOrder" class="accept-item">
        <van-cell title="东坑店→塘厦店" value="联盟商品" is-link center @click="toBusinessOrderView" />
      </div> -->
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getTransOuts } from '@/api/trans_out'
export default {
  components: { myNavBar },
  data() {
    return {
      list: [],
      showEmpty: false,
      hasBusinessOrder: false
    }
  },
  created() {
    getTransOuts().then(res => {
      this.list = res.data.list
      this.hasBusinessOrder = res.data.has_business_order
      this.showEmpty = this.list.length === 0 && !this.hasBusinessOrder
    })
  },
  methods: {
    toDetailView(transOrderId = null) {
      this.$router.push({
        path: '/trans-out-detail',
        query: {
          trans_order_id: transOrderId
        }
      })
    },
    toBusinessOrderView() {
      this.$router.push({
        path: '/trans-out-business-order'
      })
    },
    toRecordView() {
      this.$router.push({
        path: '/trans-out-record'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .accept-item {
    margin-bottom: 10px;
  }
  .list {
    padding: 10px 10px 0 10px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
</style>
